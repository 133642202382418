<template>
	<div class="page mypage">
		<el-tabs value="first">
			<el-tab-pane label="优惠券设置" name="first">
				<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
					:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
					:showAdd="$hasAccess('market.coupon/add')" addName="新增类型" @getList="getList" :showPrint="true" :showOutput="true" @clearSearch="clearSearch"
					@addItemInTable="addOne">

					<!--搜索自定义内容-->
					<el-form-item slot="search-item" class="width150">
						<el-input v-model="searchFormData.coupon_id" size="small" clearable placeholder="按优惠券ID"></el-input>
					</el-form-item>
					<el-form-item slot="search-item" >
						<el-input v-model="searchFormData.coupon_name" size="small" clearable placeholder="按优惠券名称查询"></el-input>
					</el-form-item>
					<el-form-item slot="search-item" class="width150">
					<el-select v-model="searchFormData.type" placeholder="优惠券类型" clearable size="small">
						<el-option v-for="(value,key) in types.type" :value="key" :label="value" :key="key">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item slot="search-item" class="width150">
					<el-select placeholder="使用场景" v-model="searchFormData.grouptype" clearable size="small">
							<el-option label="通用" :value="10"></el-option>
							<el-option label="指定站点" :value="20"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item slot="search-item">
						<el-select v-model="searchFormData.group_id" filterable size="small" multiple remote
								reserve-keyword clearable placeholder="请选择小区" v-if="$hasAccess('device.group/view')"
								:remote-method="remoteGroup" :loading="groupLoading">
								<el-option v-for="item in groupList" :key="item.group_id" :label="item.group_name"
									:value="item.group_id">
								</el-option>
							</el-select>
					</el-form-item>
					<el-form-item slot="search-item" class="width150">
						<el-select placeholder="支付类型" v-model="searchFormData.support_type" clearable size="small">
							<el-option v-for="(value,key) in types.support_type" :label="value" :key="key" :value="key"></el-option>
						</el-select>
					</el-form-item>
					<!--表格内容-->
					<vxe-table-column slot="table-item" field="coupon_id" title="ID" align="center" min-width="30px" />
					<vxe-table-column slot="table-item" field="coupon_name" title="优惠名称" align="center" min-width="100px" />
					<vxe-table-column slot="table-item" field="coupon_discount" title="折扣" align="center" min-width="30px" />
					<vxe-table-column slot="table-item" field="coupon_type_text" title="有效期类型" align="center" min-width="50px">
						<template v-slot="{ row }">
							<span>{{types.coupon_type[row.coupon_type]||'未知类型'}}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="type_text" title="优惠券类型" align="center" min-width="50px" >
						<template v-slot="{ row }">
							<span>{{types.type[row.type]||'未知类型'}}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="grouptype_text" title="使用类型" align="center" min-width="50px" />
					<vxe-table-column slot="table-item" field="max_money" title="金额" align="center" min-width="50px" />
					<vxe-table-column slot="table-item" field="groupnames" title="使用场景" align="center" min-width="50px">
						<template v-slot="{ row }">
							<span>
								{{row.groupnames?row.groupnames:types.grouptype[row.grouptype]}}
							</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="support_type_text" title="支付类型" align="center" min-width="50px">
						<template v-slot="{ row }">
							<span>{{types.support_type[row.support_type]|| supportTypes(row.support_type)}}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="sendnum" title="发送数量" align="center" min-width="80px" />
					<vxe-table-column slot="table-item" field="usenum" title="使用数量" align="center" min-width="80px" />
					<vxe-table-column slot="table-item" field="start_time" title="开始时间" align="center" min-width="80px" />
					<vxe-table-column slot="table-item" field="end_time" title="结束时间" align="center" min-width="80px" />
					<vxe-table-column slot="table-item" field="is_spread_text" title="是否推广" align="center" min-width="50px">
						<template v-slot="{row}">
							<span>{{row.is_spread?'是':'否'}}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="send_limit" title="推广限制" align="center" min-width="50px">
						<template v-slot="{row}">
							<span>{{row.send_limit>0?row.send_limit:'无限制'}}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="qrCode_type_text" title="二维码领券" align="center" min-width="80px" />
					<vxe-table-column slot="table-item" title="操作" align="center" width="300px">
						<template v-slot="{ row }">
							<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.coupon/edit')">编辑
							</el-button>
							<el-button size="small" plain @click="editOut(row)" v-if="$hasAccess('market.coupon/edit')">发放
							</el-button>
							<el-button size="small" plain @click="deleteOne(row)">删除</el-button>
							<el-button size="small" plain @click="qrCode(row)">二维码</el-button>
						</template>
					</vxe-table-column>
				</Tables>
			</el-tab-pane>
			<el-tab-pane label="推广提额" name="second">
				<sendLimitUp></sendLimitUp>
			</el-tab-pane>
		</el-tabs>
		<!-- 新增 -->
		<el-dialog title="新增类型" :visible.sync="addDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="600px">
			<el-form :model="addDialogFormData" :rules="rules" ref="addForm" label-width="150px" class="form">
				<el-form-item label="优惠名称" prop="coupon_name">
					<el-input v-model="addDialogFormData.coupon_name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="优惠券类型" prop="type">
					<el-radio-group v-model="addDialogFormData.type">
						<el-radio v-for="(value,key) in types.type" :key="key" :label="key">{{ value }}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="使用类型" prop="type">
					<el-radio-group v-model="addDialogFormData.grouptype">
						<el-radio :label="10">通用</el-radio>
						<el-radio :label="20">指定站点</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="支付类型" prop="type">
					<el-checkbox-group v-model="addDialogFormData.support_type">
						<el-checkbox v-for="(value,key) in types.support_type" :key="key" :label="key">{{ value }}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item label="发放小区" prop="group_id" v-if="addDialogFormData.grouptype==20">
					<el-select v-model="addDialogFormData.group_id" filterable size="small" multiple remote
						reserve-keyword clearable placeholder="请选择小区" v-if="$hasAccess('device.group/view')"
						:remote-method="remoteGroup" :loading="groupLoading">
						<el-option v-for="item in groupList" :key="item.group_id" :label="item.group_name"
							:value="item.group_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="折扣" prop="coupon_discount" v-if="addDialogFormData.type==10">
					<el-input-number :min="1" :max="9.5" :step="0.1" :precision="1"
						v-model="addDialogFormData.coupon_discount" clearable />
				</el-form-item>
				<el-form-item label="最多优惠（元）" prop="max_money" v-if="addDialogFormData.type==10">
					<el-input-number :min="0" :step="1" v-model="addDialogFormData.max_money" clearable />
				</el-form-item>
				<el-form-item label="现金优惠（元）" prop="max_money" v-if="addDialogFormData.type==20 || addDialogFormData.type==40">
					<el-input-number :min="0" :step="1" :precision="1" v-model="addDialogFormData.max_money" clearable />
				</el-form-item>

				<el-form-item label="满足金额（元）" prop="coupon_discount" v-if="addDialogFormData.type==50 ||addDialogFormData.type==60 ">
					<el-input-number :min="0" :step="1" v-model="addDialogFormData.coupon_discount" clearable />
				</el-form-item>
				<el-form-item label="减去金额（元）" prop="max_money" v-if="addDialogFormData.type==50 ||addDialogFormData.type==60 ">
					<el-input-number :min="0" :step="1" v-model="addDialogFormData.max_money" clearable />
				</el-form-item>


				<el-form-item label="有效期类型" prop="coupon_type">
					<el-radio-group v-model="addDialogFormData.coupon_type">
						<el-radio :label="10">限时</el-radio>
						<el-radio :label="20">普通</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="开始时间" prop="select_time" v-if="addDialogFormData.coupon_type==20">
					<el-date-picker class="from-item-width" unlink-panels v-model="addDialogFormData.select_time"
						type="datetime" placeholder="开启时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="select_time2" v-if="addDialogFormData.coupon_type==20">
					<el-date-picker class="from-item-width" unlink-panels v-model="addDialogFormData.select_time2"
						type="datetime" placeholder="结束时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="有效天数" prop="day" v-if="addDialogFormData.coupon_type==10">
					<el-input-number :min="1" :step="1" v-model="addDialogFormData.day" />
				</el-form-item>
				<el-form-item label="是否推广" prop="is_spread">
					<el-switch v-model="addDialogFormData.is_spread" active-value="1" inactive-value="0" clearable />
				</el-form-item>
				<el-form-item v-if="addDialogFormData.is_spread" label="每月推广上限" prop="send_limit">
					<el-input-number :min="0" :step="1" v-model="addDialogFormData.send_limit" /> 0为无限制
				</el-form-item>
				<el-form-item label="二维码领券" prop="type">
					<el-radio-group v-model="addDialogFormData.qrCode_type">
						<el-radio v-for="(value,key) in types.qrCode_type" :key="key" :label="key">{{ value }}</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveAdd()"
					v-if="$hasAccess('market.coupon/add')">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>

		<!-- 编辑 -->
		<el-dialog title="编辑优惠" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="600px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="150px" class="form">
				<el-form-item label="优惠名称" prop="coupon_name">
					<el-input v-model="editDialogFormData.coupon_name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="优惠券类型" prop="type">
					<el-radio-group v-model="editDialogFormData.type">
						<el-radio v-for="(value,key) in types.type" :key="key" :label="key">{{ value }}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="使用类型" prop="type">
					<el-radio-group v-model="editDialogFormData.grouptype">
						<el-radio :label="10">通用</el-radio>
						<el-radio :label="20">指定站点</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="支付类型" prop="type">
					<el-checkbox-group v-model="editDialogFormData.support_type">
						<el-checkbox v-for="(value,key) in types.support_type" :key="key" :label="key">{{ value }}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item label="发放小区" prop="groupIds" v-if="editDialogFormData.grouptype==20">
					<el-select v-model="groupIds" filterable size="small" multiple remote reserve-keyword clearable
						placeholder="请选择小区" v-if="$hasAccess('device.group/view')" :remote-method="remoteGroup"
						:loading="groupLoading">
						<el-option v-for="item in groupList" :key="item.group_id" :label="item.group_name"
							:value="item.group_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="折扣" prop="coupon_discount" v-if="editDialogFormData.type==10">
					<el-input-number :min="1" :max="9.5" :step="0.1" :precision="1"
						v-model="editDialogFormData.coupon_discount" clearable />
				</el-form-item>
				<el-form-item label="最多优惠（元）" prop="max_money" v-if="editDialogFormData.type==10">
					<el-input-number :min="0" :step="1" v-model="editDialogFormData.max_money" clearable />
				</el-form-item>
				<el-form-item label="现金优惠（元）" prop="max_money" v-if="editDialogFormData.type==20 || editDialogFormData.type==40">
					<el-input-number :min="0" :step="1" :precision="1" v-model="editDialogFormData.max_money" clearable />
				</el-form-item>
				<el-form-item label="满足金额（元）" prop="coupon_discount" v-if="editDialogFormData.type==50 ||editDialogFormData.type==60 ">
					<el-input-number :min="0" :step="1" v-model="editDialogFormData.coupon_discount" clearable />
				</el-form-item>
				<el-form-item label="减去金额（元）" prop="max_money" v-if="editDialogFormData.type==50 ||editDialogFormData.type==60 ">
					<el-input-number :min="0" :step="1" v-model="editDialogFormData.max_money" clearable />
				</el-form-item>
				<el-form-item label="有效期类型" prop="coupon_type">
					<el-radio-group v-model="editDialogFormData.coupon_type">
						<el-radio :label="10">限时</el-radio>
						<el-radio :label="20">普通</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="开始时间" prop="select_time" v-if="editDialogFormData.coupon_type==20">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.start_time"
						type="datetime" placeholder="开启时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="select_time2" v-if="editDialogFormData.coupon_type==20">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.end_time"
						type="datetime" placeholder="结束时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="有效天数" prop="day" v-if="editDialogFormData.coupon_type==10">
					<el-input-number :min="1" :step="1" v-model="editDialogFormData.day" />
				</el-form-item>
				<el-form-item label="是否推广" prop="is_spread">
					<el-switch v-model="editDialogFormData.is_spread" active-value='1' inactive-value='0'></el-switch>
				</el-form-item>
				<el-form-item v-if="editDialogFormData.is_spread" label="每月推广上限" prop="send_limit">
					<el-input-number :min="0" :step="1" v-model="editDialogFormData.send_limit" /> 0为无限制
				</el-form-item>
				<el-form-item label="二维码领券" prop="type">
					<el-radio-group v-model="editDialogFormData.qrCode_type">
						<el-radio v-for="(value,key) in types.qrCode_type" :key="key" :label="key">{{ value }}</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()"
					:disabled="editDisabled" v-if="$hasAccess('market.coupon/edit')">保 存 编 辑
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
		<!-- 发放 -->
		<el-dialog title="发放" :visible.sync="outDialogShow" :close-on-click-modal="false" :close-on-press-escape="false"
			width="600px">
			<el-form :model="outDialogFormData" :rules="rules" ref="outForm" label-width="150px" class="form">
				<el-form-item label="用户ID" prop="fans_id" v-if="outDialogFormData.send_type==10">
					<el-input v-model="outDialogFormData.fans_id" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="发放城市" prop="city" v-if="outDialogFormData.send_type==20">
					<el-radio-group v-model="outDialogFormData.city">
						<el-radio :label="10">全部</el-radio>
						<el-radio :label="20">苏州</el-radio>
						<el-radio :label="30">无锡</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="数量" prop="coupon_num">
					<el-input-number :min="1" :max="10" :step="1" v-model="outDialogFormData.coupon_num" clearable />
				</el-form-item>
				<el-form-item label="发放类型" prop="send_type">
					<el-radio-group v-model="outDialogFormData.send_type">
						<el-radio :label="10">用户ID</el-radio>
						<el-radio :label="20">城市</el-radio>
					</el-radio-group>
				</el-form-item>
				<label style="color: red ;margin-left: 60px;">支持多账户，请用英文","将账号分开，且不要有空格</label>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveOut()"
					:disabled="editDisabled" v-if="$hasAccess('market.coupon/edit')">提 交
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>

		<el-dialog title="优惠券二维码" :visible.sync="showQrCode" width="500px" height="650px" center class="qrcode">
			<qrcode :value="qrcode_url" :options="{width:400}"></qrcode>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import QrCode from '@chenfengyuan/vue-qrcode'
	import sendLimitUp from './sendLimitUp'

	export default {
		name: 'market-coupon-list',
		components: {
			Tables,
			sendLimitUp
		},
		data() {
			return {
				// 表格信息
				tableName: '充值优惠',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {},
				selecttime: {},
				groupList: [],
				groupIds: [],
				types: {
					coupon_type: {
						10: '限时',
						20: '普通'
					},
					type: {
						10: '折扣',
						20: '现金',
						30: '免服务费',
						40: '代金券',
						50: '满减券',
						60: '红包券',
					},
					grouptype: {
						10: '通用',
						20: '指定站点'
					},
					support_type: {
						10: '全部',
						20: '非电卡',
						30: '微信',
						40: '支付宝',
						50: 'APP',
						60: '农行',
						70: '电卡'

					},
					qrCode_type:{
						0:"不能领券",1:"每人一次",2:"每人每天一次"
					}
				},
				// 新增
				addDialogShow: false,
				addDialogFormData: {},
				groupLoading: false,
				rules: {
					coupon_name: [{
						required: true,
						message: '请输入优惠名称',
						trigger: 'change'
					}],
					coupon_type: [{
						required: true,
						message: '请选择优惠类型',
						trigger: 'change'
					}],
					coupon_discount: [{
						required: true,
						message: '请输入折扣',
						trigger: 'change'
					}],
					max_money: [{
						required: true,
						message: '请输入最多优惠',
						trigger: 'change'
					}],
					day: [{
						required: true,
						message: '请输入有效天数',
						trigger: 'change'
					}],
					city: [{
						required: true,
						message: '请选择城市',
						trigger: 'change'
					}]
				},
				// 编辑
				editDialogShow: false,
				outDialogShow: false,
				editDialogFormData: {},
				outDialogFormData: {},
				editDialogFormOriData: {},
				editNeedData: ['coupon_id', 'coupon_name', 'coupon_money', 'coupon_type', 'type', 'grouptype',
					'support_type', 'group_id', 'gift_money'
				],
				showQrCode: false,
				qrcode_url: "https://test"
			}
		},
		computed: {
			editDisabled() {
				for (let item of this.editNeedData) {
					if (this.editDialogFormData[item] != this.editDialogFormOriData[item]) {
						return false
					}
				}
				return false
			}
		},
		mounted() {
			this.init();
		},
		activated() {
			this.init();
		},
		methods: {
			// 初始化请求
			init() {
				this.remoteGroup();
			},
			// 表格列表
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true
				const searchData = this.searchFormData
				const res = await this.$api.Market.GetCouponList(params, searchData)
				this.tableData = res.data;
				for(let v of this.tableData){
					v.coupon_type_text = this.types.coupon_type[v.coupon_type]||'未知类型';
					v.type_text = this.types.type[v.type]||'未知类型';
					v.grouptype_text = this.types.grouptype[v.grouptype]||'未知类型';
					v.support_type_text = this.types.support_type[v.support_type]||'未知类型';
					v.is_spread_text = v.is_spread?'是':'否'
					v.qrCode_type_text = this.types.qrCode_type[v.qrCode_type];
				}
				this.totalPage = res.total
				this.isLoading = false;

				setTimeout(()=>{
					let htmlnode = document.getElementsByClassName("vxe-table--body-wrapper");
					for(let nd of htmlnode){
						nd.style.height = 'auto';
					}
					htmlnode = document.getElementsByTagName("table");
					for(let nd of htmlnode){
						nd.style.width = '100%';
					}
				}, 200);
			},
			async remoteGroup(query) {
				if (query !== '') {
					this.groupLoading = true
					const params = {
						token: this.$store.state.user.token,
						page: 1,
						size: 1000
					}
					const res = await this.$api.Device.GetGroupList(params, {
						group_name: query
					})
					this.groupList = res.data
					this.groupLoading = false
				} else {
					this.groupList = []
				}
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			//获取支付类型
			supportTypes(items){
				var data = this.types.support_type;
				var array = items.split(',');
				var str = '';
				array.forEach(element => {
					if(typeof(data[element]) != undefined){
						str = str+data[element] + '\n'
					}
				});
				return str;

			},
			// 重置form
			resetFormData() {
				this.addDialogFormData = {
					coupon_type: 10,
					type: '10',
					grouptype: 10,
					support_type:['10']
				}
				this.groupIds = [];
				this.editDialogFormData = {}
				this.editDialogFormOriData = {}
				this.outDialogFormData = {
					send_type: 10,
					coupon_num: 1,
				}
				if (this.$refs.addForm) {
					this.$refs.addForm.resetFields()
				}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 新增
			addOne() {
				this.resetFormData()
				this.addDialogShow = true
			},
			// 编辑
			async editOne(row) {
				this.resetFormData()
				const params = {
					token: this.$store.state.user.token,
					coupon_id: row.coupon_id
				}
				const res = await this.$api.Market.GetCouponDetail(params)
				res.type = res.type.toString();
				if(res.support_type.indexOf(',') !== false){
					res. support_type =res.support_type.split(',');
				}
				if (res) {
					const {
						coupon_id,
						coupon_name,
						coupon_discount,
						max_money,
						coupon_type,
						type,
						grouptype,
						support_type,
						group_id,
						start_time,
						end_time,
						day,
						is_spread,
						qrCode_type,
						send_limit
					} = res
		
					this.editDialogFormData = {
						coupon_id,
						coupon_name,
						coupon_discount,
						max_money,
						type,
						grouptype,
						support_type,
						group_id,
						coupon_type,
						start_time,
						end_time,
						day,
						is_spread:is_spread.toString(),
						qrCode_type:qrCode_type.toString(),
						send_limit
					}
					this.editDialogFormOriData = {
						coupon_id,
						coupon_name,
						coupon_discount,
						max_money,
						type,
						grouptype,
						support_type,
						group_id,
						coupon_type,
						start_time,
						end_time,
						day,
						is_spread:is_spread.toString(),
						qrCode_type,
						send_limit
					}

					if (res.group_id) {
						const params = {
							token: this.$store.state.user.token,
							size: 1000
						}
						this.$api.Device.GetGroupList(params).then(gres => {
							this.groupList = gres.data
							const groupIds = res.group_id.toString().split(',')
							for (let i = 0; i < groupIds.length; i++) {
								groupIds[i] = parseInt(groupIds[i])
							}
							this.groupIds = groupIds
						})
					}

				}
				this.editDialogShow = true
			},
			//发放
			async editOut(row) {
				this.resetFormData()
				this.outDialogFormData.coupon_id = row.coupon_id;
				this.outDialogShow = true
			},
			// 删除
			deleteOne(row) {
				this.$confirm('确定要删除该优惠吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						coupon_id: row.coupon_id
					}
					await this.$api.Market.DeleteCoupon(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.addDialogShow = false
				this.editDialogShow = false
				this.outDialogShow = false
			},
			// 新增保存
			saveAdd() {
				this.$refs.addForm.validate(async valid => {
					if (valid) {
						if (this.addDialogFormData.select_time != undefined) {
							this.addDialogFormData.start_time = parseInt(this.addDialogFormData.select_time
								.getTime() / 1000);
							this.addDialogFormData.end_time = parseInt(this.addDialogFormData.select_time2
								.getTime() / 1000);
						}
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.addDialogFormData)
						}
						await this.$api.Market.AddCoupon(params)
						this.$notify({
							title: '成功',
							message: '新增成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			// 编辑保存
			saveEdit() {
				this.editDialogFormData.group_id = this.groupIds.join(',')
				this.$refs.editForm.validate(async valid => {
					if (valid) {
						if (this.editDialogFormData.coupon_type == 20) {
							this.selecttime.start_time = this.editDialogFormData.start_time.length ==
								undefined ? parseInt(this.editDialogFormData.start_time.getTime() / 1000) :
								this.editDialogFormData.start_time;
							this.selecttime.end_time = this.editDialogFormData.end_time.length == undefined ?
								parseInt(this.editDialogFormData.end_time.getTime() / 1000) : this
								.editDialogFormData.end_time;
							this.editDialogFormData.start = this.selecttime.start_time;
							this.editDialogFormData.end = this.selecttime.end_time;
						}
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.editDialogFormData)
						}
						await this.$api.Market.EditCoupon(params)
						this.$notify({
							title: '成功',
							message: '编辑成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			// 提交发放代金券保存
			saveOut() {
				this.$refs.outForm.validate(async valid => {
					if (valid) {
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.outDialogFormData)
						}
						await this.$api.Market.OutCoupon(params)
						this.$notify({
							title: '成功',
							message: '提交成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			qrCode(row){ //生成二维码
				this.showQrCode = true;
				if(window.location.href.includes('yz.show.jsyzcd.cn')){
					this.qrcode_url = "https://yz.api.jsyzcd.cn/couponsCode/?coupon_id=" + row.coupon_id;
				}else{
					this.qrcode_url = "https://qc.api.7wango.cn/couponsCode/?coupon_id=" + row.coupon_id;
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
.el-radio{
	margin-bottom: 10px;
}
.width150{
	width: 150px;

}


</style>
