<template>
    <div>
    <tableview ref="table" :url="$api.Market.ActivityCommon.list" v-model="searchValue" :baseSearch="{tp:10}" :checkFun="checkFun">
        <template #search>
            <el-input size="small" v-model="searchValue.cfg_managerName" placeholder="搜索人员姓名" clearable></el-input>
            <el-input size="small" v-model="searchValue.cfg_coupon" placeholder="搜索优惠券ID" clearable></el-input>
            <el-input size="small" v-model="searchValue.cfg_couponName" placeholder="搜索优惠券名称" clearable></el-input>
            <el-select size="small" v-model="searchValue.status" placeholder="状态" clearable>
                <el-option label="有效" :value="1"></el-option>
                <el-option label="无效" :value="0"></el-option>
            </el-select>
        </template>
        <template #bar>
            <el-button @click="addItem" size="small" icon="el-icon-plus" plain>添加</el-button>
        </template>
        <template #column>
            <el-table-column prop="id" label="#"></el-table-column>
            <el-table-column prop="cfg.managerName" label="人员名单">
                <template slot-scope="{row}">
                    {{ row.cfg.managerName.join("、") }}
                </template>
            </el-table-column>
            <el-table-column prop="cfg.couponName" label="优惠券名称">
                <template slot-scope="{row}">
                    {{ row.cfg.couponName.join("、") }}
                </template>
            </el-table-column>
            <el-table-column prop="cfg.coupon" label="优惠券ID">
                <template slot-scope="{row}">
                    {{ row.cfg.coupon.join("、") }}
                </template>
            </el-table-column>
            <el-table-column prop="cfg.num" label="每月提额数量"></el-table-column>
            <el-table-column prop="phone" label="有效期">
                <template slot-scope="{row}">
                    {{ row.btime.substr(0,10) }}~{{ row.etime.substr(0,10) }}
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态">
                <template slot-scope="{row}">
                    {{ row.status == 1?'有效':'无效' }}
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="{row}">
                    <el-button size="small" plain @click="editItem(row)">编辑</el-button>
                    <el-button size="small" plain @click="delItem(row)">删除</el-button>
                </template>
            </el-table-column>
        </template>
    </tableview>
    <el-dialog title="新增/编辑" :visible.sync="editShow" :close-on-click-modal="false" :close-on-press-escape="false" width="600px">
        <el-form :model="formData" :rules="rules" ref="editForm" label-width="120px" class="form">
            <el-form-item label="选择人员" prop="cfg.manager">
                <el-select v-model="formData.cfg.manager" size="small" multiple filterable>
                    <el-option v-for="item in managerList" :label="item.nickname" :value="item.login_id" :key="item.login_id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="选择优惠券" prop="cfg.coupon">
                <el-select v-model="formData.cfg.coupon" size="small" multiple>
                    <el-option v-for="item in couponList" :label="item.coupon_name" :value="item.coupon_id" :key="item.coupon_id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="每月提额数量" prop="cfg.num">
                <el-input v-model="formData.cfg.num" size="small" />
            </el-form-item>
            <el-form-item label="开始时间" prop="btime">
                <el-date-picker class="from-item-width" unlink-panels v-model="formData.btime" type="date" value-format="yyyy-MM-dd" placeholder="开启时间"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="etime">
                <el-date-picker class="from-item-width" unlink-panels v-model="formData.etime" type="date" value-format="yyyy-MM-dd" placeholder="结束时间"></el-date-picker>
            </el-form-item>
            <el-form-item label="是否有效" prop="status">
                <el-switch v-model="formData.status" :active-value="1" :inactive-value="0" />
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" size="small" plain icon="el-icon-check" @click="saveItem()">保 存</el-button>
            <el-button size="small" plain icon="el-icon-close" @click="editShow=false">取 消</el-button>
        </div>
    </el-dialog>        
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { MessageBox, Notification , Message} from 'element-ui'
import tableview from '@/components/tableview.vue';
import API from '@/service/api.js'

const searchValue = ref({});
const editShow = ref(false);
const formData = ref({cfg:{manager:[],coupon:[]}});
// 管理员列表
const managerList = ref({});
// 优惠券列表
const couponList = ref({});
onMounted(()=>{
    API.Device.managerList({}).then((res)=>{
        managerList.value = res;
    });
    API.Market.Coupon.minlist({is_spread:1}).then((res)=>{
        couponList.value = res;
    });
});
let rules = {
    btime: [{
        required: true,
        message: '请输入开始时间',
        trigger: 'change'
    }],
    etime: [{
        required: true,
        message: '请输入结束时间',
        trigger: 'change'
    }],
    "cfg.num":[{
        required: true,
        message: '请输提额数量',
        trigger: 'change'
    }],
};
//数据检查
function checkFun(data){
    for(let v of data){
        v.cfg = JSON.parse(v.cfg);
    }
}
//添加配置
function addItem(){
    editShow.value = true;
    formData.value = {name:'',status:1,cfg:{manager:[],coupon:[]}};
}
//编辑配置
function editItem(row){
    editShow.value = true;
    formData.value = Object.assign({}, row);
    delete formData.value._XID;
}
const editForm = ref(null);
const table = ref(null);
//删除配置
function delItem(row){
    MessageBox.confirm('确定要删除该记录吗', '提示', {
		cancelButtonClass: 'btn-custom-cancel',
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning'
	}).then(async () => {
		await API.Market.ActivityCommon.del({id:row.id})
		Notification({
			title: '成功',
			message: '删除成功',
			type: 'success'
		})
		table.value.getlist();
	})
}
//保存配置
function saveItem(){
    editForm.value.validate(async valid => {
        if (valid) {
            formData.value.cfg.managerName = [];
            for(let v of formData.value.cfg.manager){
                for(let m of managerList.value){
                    if(v == m.login_id){
                        formData.value.cfg.managerName.push(m.nickname);
                        break;
                    }
                }
            }
            formData.value.cfg.couponName = [];
            for(let v of formData.value.cfg.coupon){
                for(let m of couponList.value){
                    if(v == m.coupon_id){
                        formData.value.cfg.couponName.push(m.coupon_name);
                        break;
                    }
                }
            }
            let params = {
                form: Object.assign({"tp":10}, formData.value)
            }
            params.form.cfg = JSON.stringify(params.form.cfg);
            params.form = JSON.stringify(params.form);
            if(formData.value.id > 0){
                await API.Market.ActivityCommon.edit(params);
            }else{
                await API.Market.ActivityCommon.add(params);
            }
            Notification({
                title: '成功',
                message: '操作成功',
                type: 'success'
            })
            editShow.value = false;
            table.value.getlist()
        }
    })
}
</script>